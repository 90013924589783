import React, { useState, useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import ScrollPositionContext from '../helpers/scroll-position-context'
import Social from './social'
import Button from 'react-bootstrap/Button';
import HamburgerMenu from 'react-hamburger-menu';
import Container from '../components/container';
import SignUpDialog from '../components/signup-dialog.js'
import { requestDemoEmail } from '../helpers/emails'
import { GetContent } from '../helpers/content.js'

function Header(info) {

  const CONTENT = GetContent();
  const [isOpen, setOpen] = useState(false);
  const [hasSignupDialog, setSignupDialog] = useState(false);
  const windowSize = useContext(WindowSizeContext)
  const scrollY = useContext(ScrollPositionContext)
  const { isDesktop, isTablet, isMobile } = getScreenSize(windowSize)
  const isScrolled = scrollY > 0;
  const styles = getStyles({ isDesktop, isTablet, isMobile, isScrolled })

  let links = (
    <>
      <Button id="header-cta" onClick={ () => (setSignupDialog(true)) } variant="dark" style={styles.cta}>{ CONTENT["header"]["cta"] }</Button>
    </>
  )

  let navigation = isMobile
    ? (
      <div style={styles.sectionRight}>
        <HamburgerMenu
          id="hamburger-menu"
          width={30}
          height={30}
          isOpen={isOpen}
          menuClicked={() => setOpen(!isOpen)}
        />
      </div>
    ) : (
      <div style={styles.sectionRight}>
        { links }
      </div>
    )

  let verticalMenu = (
    <div style={styles.verticalMenu}>
      { links }
    </div>
  )

  let logo = CONTENT['logo'] !== 'none'
    ? (<img style={CONTENT['logoStyle'] || styles.logoImage} src={CONTENT['logo']} />)
    : (CONTENT['name'])

  return (
    <Container isHeader={true}>
      <div style={styles.headerContainer}>
        <div style={styles.logo}>{ logo }</div>
        { navigation }
        { isOpen && verticalMenu }
        { hasSignupDialog && (
          <SignUpDialog
            onClose={() => (setSignupDialog(false))}
          />
        )}
      </div>
    </Container>
  )
}

function getStyles({ isDesktop, isTablet, isMobile, isScrolled }) {
  let headerContainer;
  if (isDesktop) {
  }

  if (isTablet) {
  }

  if (isMobile) {
  }

  if (isScrolled) {
  }

  let styles = {
    logo: {
      fontSize: '44px',
      fontWeight: '400',
    },
    logoImage: {
      height: '75px',
      maxWidth: '90px',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // Hack for 100% wide heading
    },
    link: {
      fontSize: '16px',
      fontWeight: '600',
      marginRight: '20px',
      color: '#000000',
      margin: "20px 20px 20px 0px"
    },
    sectionRight: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    header: {
      fontWeight: "700",
      fontSize: "44px",
    },
    subHeader: {
      fontSize: "25px",
    },
    verticalMenu: {
      display: 'flex',
      position: 'fixed',
      zIndex: 4,
      top: "105px",
      left: "0px",
      flexDirection: "column",
      justifyContent: "space-around",
      backgroundColor: "white",
      width: "100vw",
      padding: "50px 50px 50px 50px",
    },
  }
  return styles;
}

export default Header;
