export function getRequestDemoLink(content) {
  let app = capitalize(content['name'] || 'no-app');
  let emailTo = content['email']['address'] || `asa.schachar+${app}@gmail.com`
  let emailCC = ''
  let emailSub = content['email']['subject'] || `${app} Demo Request`;
  let emailBody = content['email']['body'] || `Hey ${app},%0d%0a%0d%0aI'd love to get a demo of ${app}.%0d%0a%0d%0aThanks!`
  return "mailto:"+emailTo+'?cc='+emailCC+'&subject='+emailSub+'&body='+emailBody;
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function requestDemoEmail(content) {
  window.location.href = getRequestDemoLink(content);
}

export default {
  requestDemoEmail,
  getRequestDemoLink,
}
