import React, { useState, useContext, useEffect } from 'react';
import { Redirect, Link, navigate } from "@reach/router";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Spinner from 'react-bootstrap/Spinner';
import { fire as firebase, db } from '../modules/firebase';
import { AuthContext } from '../helpers/auth-context';

function LoginPage() {
  const { user, updateUser } = useContext(AuthContext);
  const [ isLoading, setLoading ] = useState(true);

  let uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };

  useEffect(() => {
    let unregisterAuthObserver = firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log('[Jibe] User found')
        setLoading(false);
        updateUser(user)
      } else {
        console.log('[Jibe] No user found')
        setLoading(false);
        updateUser(null)
      }
    });
    return () => {
      unregisterAuthObserver();
    }
  }, [])

  const loginComponents = (
    <div style={styles.content} className="shadow-xl">
      <div style={styles.header}>Welcome to Jibe</div>
      <div style={styles.subHeader}>Login to continue</div>
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      />
    </div>
  )

  const loadingComponents = (
    <>
      <Spinner animation="border" />
    </>
  )

  return (
    <div style={styles.container}>
      { isLoading ? loadingComponents : loginComponents }
    </div>
  );
}

const styles = {
  header: {
    fontSize: '35px',
    fontWeight: '700',
    marginBottom: '10px',
  },
  subHeader: {
    fontSize: '24px',
    marginBottom: '30px',
  },
  container: {
    color: 'black',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    borderRadius: '10px',
    border: '1px solid #dedede',
    padding: '50px 30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: '300px',
    margin: '10px',
  },
}

export default LoginPage;
