import React, { useState, useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Button from 'react-bootstrap/Button';
import Social from './social'
import SignUpDialog from '../components/signup-dialog.js'
import { GetContent } from '../helpers/content.js'
import Container from '../components/container'
import { requestDemoEmail } from '../helpers/emails'
import {
  APP_PADDING_DESKTOP,
  APP_PADDING_TABLET,
  APP_PADDING_MOBILE,
} from '../styles/global'

function Demo(info) {

  const CONTENT = GetContent();
  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))
  const [hasSignupDialog, setSignupDialog] = useState(false);

  return (
    <Container isStripe={true}>
      <div style={styles.demoContainer}>
        <div style={styles.demoContent}>
          <h4 style={styles.demoHeader}>{ CONTENT["demo"]["preTitle"] }</h4>
          <h1 style={styles.demoTitle}>{ CONTENT["demo"]["title"] }</h1>
          <Button id="footer-cta" onClick={() => (setSignupDialog(true))} variant="dark" style={styles.cta}>{ CONTENT["demo"]["cta"] }</Button>
        </div>
      </div>
      { hasSignupDialog && (
        <SignUpDialog
          onClose={() => (setSignupDialog(false))}
        />
      )}
    </Container>
  )
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let demoContainer, header, subHeader;
  if (isDesktop) {
    demoContainer = {
    };
  }

  if (isTablet) {
    demoContainer = {
    };
    header = {
      fontSize: "45px",
    };
    subHeader = {
      fontSize: "20px",
    };
  }

  if (isMobile) {
    demoContainer = {
    };
    header = {
      fontSize: "45px",
    };
    subHeader = {
      fontSize: "20px",
    };
  }

  let styles = {
    demoContainer: {
      ...demoContainer,
    },
    demoContent: {
      margin: '50px 0px',
    },
    demoHeader: {
    },
    demoTitle: {
      margin: '20px 0px',
      maxWidth: '600px',
    },
    header: {
      fontSize: "55px",
      ...header,
    },
    subHeader: {
      fontSize: "25px",
      ...subHeader,
    },
    logo: {
      width: "50px",
    },
  }

  return styles;
}

export default Demo;
