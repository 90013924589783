export function track(event) {
  if (window.heap && window.heap.track) {
    window.heap.track(event);
  }
}

export function identify(id) {
  if (window.heap && window.heap.identify) {
    window.heap.identify(id);
  }
}


export default {
  track,
  identify,
}
