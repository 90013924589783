import React, { useContext } from 'react';
import Social from './social'
import packageJson from '../../package.json'
import Container from '../components/container';
import { GetContent } from '../helpers/content.js'

function Footer() {
  const CONTENT = GetContent();
  let email = CONTENT['email']['address'] || `asa.schachar+${CONTENT['name'].toLowerCase()}@gmail.com`

  return (
    <Container>
      <div style={styles.footerContainer}>
        <div style={styles.linksContainer}>
          <div style={styles.logo}>{CONTENT['name']}</div>
          <a href="#">Home</a>
          <a href={`mailto:${email}`}>Contact</a>
        </div>
        <p style={styles.copyright}>
          © {CONTENT['name'].toUpperCase()}, Inc 2021. All Rights Reserved.
        </p>
      </div>
    </Container>
  )
}
let styles = {
  linksContainer: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: "column",
    marginBottom: "100px",
  },
  logo: {
    width: "50px",
    margin: "20px 0px",
    fontSize: "30px",
    fontWeight: "400",
  },
  footerContainer: {
    margin: "40px 0px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  copyright: {
    color: "rgb(179, 179, 179)",
    marginBottom: "0px",
    fontSize: "14px",
    textAlign: "center",
    width: "100%",
  },
}

export default Footer;
