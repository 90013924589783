import React, { useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Social from './social'
import { GetContent } from '../helpers/content.js'
import EmailSignup from './email-signup.js'
import {
  APP_PADDING_DESKTOP,
  APP_PADDING_TABLET,
  APP_PADDING_MOBILE,
  APP_MAX_WIDTH,
} from '../styles/global'

function Hero(info) {

  const CONTENT = GetContent();
  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))

  let video = null
  let videoLink = CONTENT["hero"]["video"]
  if (videoLink) {
    video = (
      <div style={styles.video}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/hLsUYvhbXUQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    )
  }

  return (
    <div style={styles.heroContainer}>
      <h2 style={styles.heroTitle}>{ CONTENT["hero"]["title"] }</h2>
      <p style={styles.subTitle}>
       { CONTENT["hero"]["subTitle"] }
      </p>
      <EmailSignup />
      { video }
    </div>
  )
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let styleMods = {};
  let heroContainer, heroTitle, subTitle;
  let video;
  if (isDesktop) {
    heroContainer = {
      padding: `0px ${APP_PADDING_DESKTOP}`,
      margin: "100px 0px 100px 0px",
    };
    heroTitle = {
    };
    subTitle = {
    };
  }

  if (isTablet) {
    heroContainer = {
      padding: `0px ${APP_PADDING_TABLET}`,
      margin: "50px 0px 100px 0px",
    };
    heroTitle = {
    };
    subTitle = {
    };
  }

  if (isMobile) {
    heroContainer = {
      padding: `0px ${APP_PADDING_MOBILE}`,
      margin: "20px 0px 30px 0px",
    };
    heroTitle = {
    };
    subTitle = {
    };
    video = {
      width: '300px',
      height: '160px',
    };
  }

  let styles = {
    heroContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      ...heroContainer,
    },
    video: {
      width: "712px",
      maxWidth: '712px',
      height: '400px',
      margin: '20px',
      boxShadow: 'rgb(0 0 0 / 30%) 0px 2px 20px',
      ...video,
    },
    subTitle: {
      fontWeight: "400",
      fontSize: "20px",
      textAlign: "center",
      maxWidth: "820px",
      ...subTitle,
    },
    heroTitle: {
      fontWeight: "700",
      fontSize: "44px",
      textAlign: "center",
      ...heroTitle,
    }
  }
  return styles;
}

export default Hero;
