import React, { useState } from 'react';
import './App.css';
import { Router, Redirect, navigate } from "@reach/router";
import LoginPage from './pages/login-page'
import PrivateRoute from './components/private-route'
import { AuthContext, AuthProvider } from './helpers/auth-context'
import NotFound from './pages/not-found'
import HelloWorld from './pages/hello-world'
import LandingPage from './pages/landing-page'
import Dashboard from './pages/dashboard'

function App() {
  const [user, setUser] = useState(null);

  const updateUser = newUser => {
    setUser(newUser);
  }

  return (
    <AuthProvider
      value={{
        user,
        updateUser
      }}
    >
      <Router>
        <HelloWorld path="/" />
        <LandingPage path="/l/:app" />
        <LandingPage path="/landing/:app" />
        <PrivateRoute as={Dashboard} path="/dashboard" />
        <PrivateRoute as={Dashboard} path="/dashboard/:page" />
        <NotFound default />
      </Router>
    </AuthProvider>
  );
}

export default App;
