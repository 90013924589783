import React from 'react';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Settings from '@material-ui/icons/Settings';
import Cancel from '@material-ui/icons/Cancel';

const iconMap = {
  file: (<InsertDriveFileOutlinedIcon />),
  settings: (<Settings />),
  close: (<Cancel />)
}

function Icon(props) {
  return iconMap[props.id] || null;
}

export default Icon;
