import React, { useContext, useEffect } from 'react';
import { Redirect, Link, navigate } from "@reach/router";
import { useLocation } from "@reach/router"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { fire, db } from '../modules/firebase';
import { AuthContext } from '../helpers/auth-context';
import Icon from './icon';

function Sidebar() {
  const location = useLocation();

  const user = fire.auth().currentUser
  const userName = user && user.displayName || '---'; 
  const pages = [
    {
      name: 'Prototypes',
      route: '/dashboard',
      icon: 'file',
    },
    {
      name: 'Settings',
      route: '/dashboard/settings',
      icon: 'settings',
    }
  ];

  const NavigationLinks = pages.map((page, index) => {
    const handleRoute = () => {
      navigate(page.route);
    };

    let backgroundColor = (location.pathname === page.route)
      ? { backgroundColor: '#DDEBF6' }
      : { backgroundColor: '#FFFFFF' };

    return (
      <div
        className="navTarget"
        key={`${index}`}
         style={{
           ...styles.link,
           ...backgroundColor
         }}
         onClick={ handleRoute }>
         <div style={styles.icon}>
           <Icon id={page.icon} />
         </div>
         <div style={styles.linkText}>
           { page.name }
         </div>
      </div>
    )
  });

  return (
    <div style={styles.sidebar}>
      <div style={styles.nameContainer}>
        <div style={styles.nameCircle}>{userName[0]}</div>
        <div style={styles.name}>{userName}</div>
      </div>
      <div style={styles.linksContainer}>
        { NavigationLinks }
      </div>
      <div style={styles.logoutContainer}>
        Sign Out
      </div>
    </div>
  );
}

const styles = {
  logoutContainer: {
    position: 'fixed',
    bottom: '0',
    margin: '10px',
  },
  linksContainer: {
    marginTop: '20px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
  },
  link: {
    padding: '10px 0px 10px 20px',
    display: 'flex',
    lineHeight: '30px',
  },
  name: {
    marginLeft: '10px',
    fontSize: '20px',
    fontWeight: '600',
  },
  nameContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  nameCircle: {
    width: '30px',
    height: '30px',
    borderRadius: '30px',
    backgroundColor: '#456AFC',
    textAlign: 'center',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  sidebar: {
    position: 'fixed',
    width: '240px',
    backgroundColor: 'rgb(255, 255, 255)',
    borderRight: '1px solid #E5E5E5',
    height: '100%',
    zIndex: 4,
    overflowX: 'hidden', /* Disable horizontal scroll */
    zIndex: 1, /* Stay on top */
    top: 0, /* Stay at the top */
    left: 0,
  },
  linkText: {
    fontSize: '18px',
    paddingLeft: '10px',
  },
  navigationIcon: {
    fill: 'white',
    width: '20px',
    height: '20px',
  },
  iconLinkAndText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
  },
  logo: {
    marginBottom: '10px',
    marginTop: '10px',
    color: 'white',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
  },
}

export default Sidebar;
