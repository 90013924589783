import React, { useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import ScrollPositionContext from '../helpers/scroll-position-context'
import getScreenSize from '../helpers/get-screen-size'
import {
  APP_PADDING_DESKTOP,
  APP_PADDING_TABLET,
  APP_PADDING_MOBILE,
} from '../styles/global'

function Container(props) {

  const windowSize = useContext(WindowSizeContext)
  const scrollY = useContext(ScrollPositionContext)
  const { isDesktop, isTablet, isMobile } = getScreenSize(windowSize)
  const isScrolled = scrollY > 0;
  const isHeader = !!props.isHeader
  const isStripe = !!props.isStripe
  const styles = getStyles({ isDesktop, isTablet, isMobile, isHeader, isScrolled, isStripe })

  return (
    <div style={styles.container}>
      <div style={styles.subContainer}>
        { props.children }
      </div>
    </div>
  )
}

function getStyles({ isDesktop, isTablet, isMobile, isHeader, isScrolled, isStripe }) {
  let styleMods = {};
  let container;
  if (isDesktop) {
    container = {
      padding: `0px ${APP_PADDING_DESKTOP}`,
    };
  }

  if (isTablet) {
    container = {
      padding: `0px ${APP_PADDING_TABLET}`,
    };
  }

  if (isMobile) {
    container = {
      padding: `0px ${APP_PADDING_MOBILE}`,
    };
  }

  if (isHeader) {
    container = {
      position: '-webkit-sticky',
      position: 'sticky',
      top: '0px',
      height: '105px',
      ...container,
    }
  }

  if (isHeader && isScrolled) {
    container = {
      zIndex: 3,
      borderBottom: '1px solid rgb(237, 237, 237)',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 8px',
      ...container,
    };
  }

  if (isStripe) {
    container = {
      backgroundColor: 'rgb(245, 245, 245)',
      border: '1px solid rgb(222, 222, 222)',
      ...container,
    }
  }


  let styles = {
    subContainer: {
      maxWidth: '1280px',
      width: '100%',
      margin: '0px auto',
    },
    container: {
      zIndex: 2,
      backgroundColor: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      ...container,
    },
  }
  return styles;
}

export default Container;
