import React from 'react';
import { getRequestDemoLink } from '../helpers/emails'

function HelloWorld() {
  return (
    <div style={styles.helloWorld}>Hi! Welcome to Jibe<br /> ♥️  Jibe <br />
      <a href={getRequestDemoLink()}>Request Invite</a>
    </div>
  );
}

const styles = {
  helloWorld: {
    margin: '20px',
  }
}

export default HelloWorld;
