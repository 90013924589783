import React, { useContext } from 'react';
import '../App.css';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import AnchorLink from './anchor-link.js';
import Container from '../components/container'
import {
  APP_PADDING_DESKTOP,
  APP_PADDING_TABLET,
  APP_PADDING_MOBILE,
  APP_MAX_WIDTH,
} from '../styles/global'

function Shelf(info) {

  const isEven = info.index % 2;
  const isFirst = info.index === 0;

  const windowSize = useContext(WindowSizeContext)
  const { isDesktop, isTablet, isMobile } = getScreenSize(windowSize)
  const styles = getStyles({ isDesktop, isTablet, isMobile, isEven, isFirst })


  let explanation = (
    <div style={styles.explanationContainer}>
      <h3>{info.title}</h3>
      <div>{info.text}</div>
    </div>
  )

  let imageDiv = (
    <div style={styles.imageContainer} className="hoverTilt hoverTiltRight">
      <img style={styles.image} src={info.imageUrl}></img>
    </div>
  )

  if (isEven && !isMobile) {
    return (
      <Container key={info.index} isStripe={true}>
        <div style={styles.shelf}>
          { imageDiv }
          { explanation }
        </div>
      </Container>
    )
  } else {
    return (
      <Container key={info.index}>
        <div style={styles.shelf}>
          { explanation }
          { imageDiv }
        </div>
      </Container>
    )
  }
}

function getStyles({ isDesktop, isTablet, isMobile, isEven, isFirst }) {
  let shelf, image, explanationContainer;
  if (isDesktop) {
    shelf = {
    }
  }

  if (isTablet) {
    shelf = {
    }
  }

  if (isEven) {
    shelf = {
    }
    image = {
      padding: '30px 30px 30px 0px',
    }
    explanationContainer = {
      padding: '30px 0px 30px 30px',
    }
  }

  if (isMobile) {
    shelf = {
      flexDirection: 'column',
    }
    image = {
      padding: '30px 0px 30px 0px',
    }
    explanationContainer = {
      padding: '0px',
    }
  }


  let styles = {
    shelf: {
      display: 'flex',
      minHeight: '300px',
      flexDirection: 'row',
      alignItems: 'stretch',
      ...shelf,
    },
    shelfTitle: {
      fontSize: '32px',
    },
    explanationContainer: {
      padding: '30px 30px 30px 0px',
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      ...explanationContainer,
    },
    imageContainer: {
      flex: '1',
    },
    image: {
      width: '100%',
      //maxHeight: '300px',
      padding: '30px 0px 30px 30px',
      ...image,
    },
  }
  return styles;
}

export default Shelf;
