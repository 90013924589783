import React, { useContext, useEffect } from 'react';
import { fire, db } from '../modules/firebase';
import PageHeader from '../components/page-header';

function Settings() {

  return (
    <div style={styles.pageContent}>
      <PageHeader header="Settings" />
    </div>
  );
}

const styles = {
  pageContent: {
    marginLeft: '240px',
  }
}

export default Settings;
