import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { GetContent } from '../helpers/content.js';
import { identify } from '../helpers/analytics.js';

function EmailSignup(props) {

  const CONTENT = GetContent();

  let [successfulSubmission, setSuccessfulSubmission] = useState(false);

  let onSubmit = (values, { setSubmitting }) => {
    identify(values.email);
    setSubmitting(false);
    setSuccessfulSubmission(true);
  }

  const styles = getStyles();

  let emailForm = (
    <Formik
      initialValues={{ email: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form
          noValidate
          onSubmit={handleSubmit}>
          <InputGroup className="mb-3">
            <Form.Control
              className="email-input"
              isInvalid={errors.email && touched.email}
              required
              type="email"
              name="email"
              onBlur={handleBlur}
              value={values.email}
              placeholder="name@example.com"
              aria-label="Email"
              aria-describedby="basic-addon2"
              onChange={handleChange}
            />
            <InputGroup.Append>
              <Button
                className="email-submit"
                disabled={isSubmitting}
                type="submit"
                variant="primary">
                Sign Up
              </Button>
            </InputGroup.Append>
            { !(errors.email && touched.email) && (<div style={styles.errorSpace}></div>) }
            { errors.email && touched.email &&  (
              <Form.Control.Feedback type="invalid" style={styles.errorContainer}>
                <div style={styles.invalidText}> { errors.email } </div>
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form>
      )}
    </Formik>
  )

  let successMessage = (
    <div style={styles.success}>
      { CONTENT["email"]["success"] || 'Success! ✅' }
    </div>
  )

  return (
    <div style={styles.emailContainer}>
      { successfulSubmission ? successMessage : emailForm }
    </div>
  )
}

function getStyles() {

  let styles = {
    success: {
      fontWeight: 600,
      color: '#5fb810',
      textAlign: 'center',
    },
    emailContainer: {
      position: 'relative',
      zIndex: 1,
      marginTop: '10px',
      width: '300px',
    },
    invalidText: {
      position: 'relative', // Prevents invalid error from moving page down
      marginTop: '4px',
    },
    errorContainer: {
      margin: '0px',
    },
    errorSpace: { // Ensure error text does not push down content
      height: '23px',
      width: '300px',
    },
  }
  return styles;
}

export default EmailSignup;
