import { fire as firebase, db } from '../modules/firebase';

export const streamPrototypes = (accountId, observer) => {
  return db.collection('prototypeList')
    .doc(accountId)
    .collection('items')
    .orderBy('created')
    .onSnapshot(observer);
};

export default {
  streamPrototypes,
}
