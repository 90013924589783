import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Configure Firebase
const config = {
  apiKey: "AIzaSyAuJR4OUKHHgSGyiVkjxLc6KEkEmXxO-70",
  authDomain: "jibe-dashboard.firebaseapp.com",
  databaseURL: "https://jibe-dashboard.firebaseio.com",
  projectId: "jibe-dashboard",
  storageBucket: "jibe-dashboard.appspot.com",
  messagingSenderId: "267823948971",
  appId: "1:267823948971:web:d8105afc0d1553e865c3ee",
  measurementId: "G-675311FRZ6"
};

firebase.initializeApp(config);

export const fire = firebase;
export const db = firebase.firestore();

db.settings({
 timestampsInSnapshots: true,
})
